define('lodash/number/random', ['exports', 'lodash/internal/baseRandom', 'lodash/internal/isIterateeCall'], function (exports, _lodashInternalBaseRandom, _lodashInternalIsIterateeCall) {

  /* Native method references for those with the same name as other `lodash` methods. */
  var nativeMin = Math.min,
      nativeRandom = Math.random;

  /**
   * Produces a random number between `min` and `max` (inclusive). If only one
   * argument is provided a number between `0` and the given number is returned.
   * If `floating` is `true`, or either `min` or `max` are floats, a floating-point
   * number is returned instead of an integer.
   *
   * @static
   * @memberOf _
   * @category Number
   * @param {number} [min=0] The minimum possible value.
   * @param {number} [max=1] The maximum possible value.
   * @param {boolean} [floating] Specify returning a floating-point number.
   * @returns {number} Returns the random number.
   * @example
   *
   * _.random(0, 5);
   * // => an integer between 0 and 5
   *
   * _.random(5);
   * // => also an integer between 0 and 5
   *
   * _.random(5, true);
   * // => a floating-point number between 0 and 5
   *
   * _.random(1.2, 5.2);
   * // => a floating-point number between 1.2 and 5.2
   */
  function random(min, max, floating) {
    if (floating && (0, _lodashInternalIsIterateeCall['default'])(min, max, floating)) {
      max = floating = undefined;
    }
    var noMin = min == null,
        noMax = max == null;

    if (floating == null) {
      if (noMax && typeof min == 'boolean') {
        floating = min;
        min = 1;
      } else if (typeof max == 'boolean') {
        floating = max;
        noMax = true;
      }
    }
    if (noMin && noMax) {
      max = 1;
      noMax = false;
    }
    min = +min || 0;
    if (noMax) {
      max = min;
      min = 0;
    } else {
      max = +max || 0;
    }
    if (floating || min % 1 || max % 1) {
      var rand = nativeRandom();
      return nativeMin(min + rand * (max - min + parseFloat('1e-' + ((rand + '').length - 1))), max);
    }
    return (0, _lodashInternalBaseRandom['default'])(min, max);
  }

  exports['default'] = random;
});