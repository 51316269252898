define('lodash/lang/isEqual', ['exports', 'lodash/internal/baseIsEqual', 'lodash/internal/bindCallback'], function (exports, _lodashInternalBaseIsEqual, _lodashInternalBindCallback) {

  /**
   * Performs a deep comparison between two values to determine if they are
   * equivalent. If `customizer` is provided it's invoked to compare values.
   * If `customizer` returns `undefined` comparisons are handled by the method
   * instead. The `customizer` is bound to `thisArg` and invoked with up to
   * three arguments: (value, other [, index|key]).
   *
   * **Note:** This method supports comparing arrays, booleans, `Date` objects,
   * numbers, `Object` objects, regexes, and strings. Objects are compared by
   * their own, not inherited, enumerable properties. Functions and DOM nodes
   * are **not** supported. Provide a customizer function to extend support
   * for comparing other values.
   *
   * @static
   * @memberOf _
   * @alias eq
   * @category Lang
   * @param {*} value The value to compare.
   * @param {*} other The other value to compare.
   * @param {Function} [customizer] The function to customize value comparisons.
   * @param {*} [thisArg] The `this` binding of `customizer`.
   * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
   * @example
   *
   * var object = { 'user': 'fred' };
   * var other = { 'user': 'fred' };
   *
   * object == other;
   * // => false
   *
   * _.isEqual(object, other);
   * // => true
   *
   * // using a customizer callback
   * var array = ['hello', 'goodbye'];
   * var other = ['hi', 'goodbye'];
   *
   * _.isEqual(array, other, function(value, other) {
   *   if (_.every([value, other], RegExp.prototype.test, /^h(?:i|ello)$/)) {
   *     return true;
   *   }
   * });
   * // => true
   */
  function isEqual(value, other, customizer, thisArg) {
    customizer = typeof customizer == 'function' ? (0, _lodashInternalBindCallback['default'])(customizer, thisArg, 3) : undefined;
    var result = customizer ? customizer(value, other) : undefined;
    return result === undefined ? (0, _lodashInternalBaseIsEqual['default'])(value, other, customizer) : !!result;
  }

  exports['default'] = isEqual;
});