define('lodash/collection/some', ['exports', 'lodash/internal/arraySome', 'lodash/internal/baseCallback', 'lodash/internal/baseSome', 'lodash/lang/isArray', 'lodash/internal/isIterateeCall'], function (exports, _lodashInternalArraySome, _lodashInternalBaseCallback, _lodashInternalBaseSome, _lodashLangIsArray, _lodashInternalIsIterateeCall) {

  /**
   * Checks if `predicate` returns truthy for **any** element of `collection`.
   * The function returns as soon as it finds a passing value and does not iterate
   * over the entire collection. The predicate is bound to `thisArg` and invoked
   * with three arguments: (value, index|key, collection).
   *
   * If a property name is provided for `predicate` the created `_.property`
   * style callback returns the property value of the given element.
   *
   * If a value is also provided for `thisArg` the created `_.matchesProperty`
   * style callback returns `true` for elements that have a matching property
   * value, else `false`.
   *
   * If an object is provided for `predicate` the created `_.matches` style
   * callback returns `true` for elements that have the properties of the given
   * object, else `false`.
   *
   * @static
   * @memberOf _
   * @alias any
   * @category Collection
   * @param {Array|Object|string} collection The collection to iterate over.
   * @param {Function|Object|string} [predicate=_.identity] The function invoked
   *  per iteration.
   * @param {*} [thisArg] The `this` binding of `predicate`.
   * @returns {boolean} Returns `true` if any element passes the predicate check,
   *  else `false`.
   * @example
   *
   * _.some([null, 0, 'yes', false], Boolean);
   * // => true
   *
   * var users = [
   *   { 'user': 'barney', 'active': true },
   *   { 'user': 'fred',   'active': false }
   * ];
   *
   * // using the `_.matches` callback shorthand
   * _.some(users, { 'user': 'barney', 'active': false });
   * // => false
   *
   * // using the `_.matchesProperty` callback shorthand
   * _.some(users, 'active', false);
   * // => true
   *
   * // using the `_.property` callback shorthand
   * _.some(users, 'active');
   * // => true
   */
  function some(collection, predicate, thisArg) {
    var func = (0, _lodashLangIsArray['default'])(collection) ? _lodashInternalArraySome['default'] : _lodashInternalBaseSome['default'];
    if (thisArg && (0, _lodashInternalIsIterateeCall['default'])(collection, predicate, thisArg)) {
      predicate = undefined;
    }
    if (typeof predicate != 'function' || thisArg !== undefined) {
      predicate = (0, _lodashInternalBaseCallback['default'])(predicate, thisArg, 3);
    }
    return func(collection, predicate);
  }

  exports['default'] = some;
});