define('lodash/object/mapKeys', ['exports', 'lodash/internal/createObjectMapper'], function (exports, _lodashInternalCreateObjectMapper) {

  /**
   * The opposite of `_.mapValues`; this method creates an object with the
   * same values as `object` and keys generated by running each own enumerable
   * property of `object` through `iteratee`.
   *
   * @static
   * @memberOf _
   * @category Object
   * @param {Object} object The object to iterate over.
   * @param {Function|Object|string} [iteratee=_.identity] The function invoked
   *  per iteration.
   * @param {*} [thisArg] The `this` binding of `iteratee`.
   * @returns {Object} Returns the new mapped object.
   * @example
   *
   * _.mapKeys({ 'a': 1, 'b': 2 }, function(value, key) {
   *   return key + value;
   * });
   * // => { 'a1': 1, 'b2': 2 }
   */
  var mapKeys = (0, _lodashInternalCreateObjectMapper['default'])(true);

  exports['default'] = mapKeys;
});