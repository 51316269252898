define("lodash/internal/indexOfNaN", ["exports"], function (exports) {
  /**
   * Gets the index at which the first occurrence of `NaN` is found in `array`.
   *
   * @private
   * @param {Array} array The array to search.
   * @param {number} fromIndex The index to search from.
   * @param {boolean} [fromRight] Specify iterating from right to left.
   * @returns {number} Returns the index of the matched `NaN`, else `-1`.
   */
  function indexOfNaN(array, fromIndex, fromRight) {
    var length = array.length,
        index = fromIndex + (fromRight ? 0 : -1);

    while (fromRight ? index-- : ++index < length) {
      var other = array[index];
      if (other !== other) {
        return index;
      }
    }
    return -1;
  }

  exports["default"] = indexOfNaN;
});