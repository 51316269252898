define("lodash/support", ["exports"], function (exports) {
  /**
   * An object environment feature flags.
   *
   * @static
   * @memberOf _
   * @type Object
   */
  var support = {};

  exports["default"] = support;
});